<template>
  <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
    <a-form-model-item has-feedback label="工地id" prop="ws_id">
      <a-input v-model="ruleForm.ws_id" type="number" disabled />
    </a-form-model-item>
    <a-form-model-item has-feedback label="工地名称" prop="ws_name">
      <a-input v-model="ruleForm.ws_name" type="string" disabled />
    </a-form-model-item>
    <a-form-model-item
      has-feedback
      label="设备IMEI"
      prop="IMEI"
      v-if="formType == 'Bind'"
    >
      <a-input v-model.number="ruleForm.IMEI" />
    </a-form-model-item>
    <a-form-model-item label="设备IMEI号" v-if="formType == 'UnBind'">
      <a-radio-group v-model="ruleForm.IMEI">
        <a-radio v-for="(item, index) in imeiList" :key="index" :value="item">
          {{ item }}
        </a-radio>
        <!-- <a-radio value="2"> Venue </a-radio> -->
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="submitForm('ruleForm')"> 提交 </a-button>
      <!-- <a-button style="margin-left: 10px" @click="resetForm('ruleForm')">
        重置
      </a-button> -->
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { DeviceBind, DeviceUnBound } from "@/api/site";
import { GetWsbinddevices } from "@/api/video";
const devicebindOrder = ["ws_id", "imei"];

export default {
  name: "DeviceBind",
  props: {
    formType: {
      type: String,
      default: "Bind",
    },
    record: {
      type: Object,
    },
  },

  data() {
    let checkPending;
    let checkAge = (rule, value, callback) => {
      clearTimeout(checkPending);
      if (!value) {
        return callback(new Error("请输入设备IMEI"));
      }
      checkPending = setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          // if (value < 18) {
          //   callback(new Error("Age must be greater than 18"));
          // } else {
          callback();
          // }
        }
      }, 1000);
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        ws_id: "",
        // checkPass: "",
        IMEI: "",
        ws_name: "",
        resource: "",
      },
      imeiList: [],
      rules: {
        pass: [{ validator: validatePass, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
        IMEI: [{ validator: checkAge, trigger: "change" }],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
    };
  },
  created() {
    // console.log(this.record, this.formType);
    this.ruleForm.ws_id = this.record.ws_id;
    this.ruleForm.ws_name = this.record.ws_name;
  },
  mounted() {
    if (this.formType == "UnBind") {
      this.GetWsbinddevices();
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //   alert("submit!");
          //
          console.log("formType", this.formType, this.ruleForm);
          if (this.formType == "Bind") {
            this.DeviceBind(DeviceBind);
          } else if (this.formType == "UnBind") {
            this.DeviceBind(DeviceUnBound);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 设备绑定解绑
    DeviceBind(requestUrl) {
      //  this.queryParam
      //   console.log(sessionStorage.getItem("token"));
      let form = {
        // token: "",
        ws_id: this.ruleForm.ws_id,
        imei: this.ruleForm.IMEI,
      };
      console.log("form", form);
      //   const param = Object.assign({}, form);
      const requestParameters = this.$order(form, devicebindOrder);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, devicebindOrder),
      };
      return requestUrl(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$emit("onClose");
        } else {
          this.$message.warning(res.data);
        }
      });
    },
    // 获取工地绑定设备信息
    GetWsbinddevices() {
      const param = {
        ws_id: this.record.ws_id,
      };
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWsbinddevices(params).then((res) => {
        // console.log("GetWsbinddevices", res.data);

        res.data.forEach((el) => {
          this.imeiList.push(el.dimei);
        });
        console.log("imeiList", this.imeiList);
      });
    },
  },
};
</script>
