<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchList"
      :bc_name="queryParam.bc_name"
      ref="SSearch"
    >
    </s-search>
    <div class="table-button">
      <a-button
        type="plain"
        icon="plus"
        @click="JumpSubpage(undefined, 'SiteEdit', '新建', 'add')"
        >新建工地</a-button
      >
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="isShowBtns.indexOf('SiteList-/api/worksite/workexport') > -1"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="SiteList"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="ws_id"
      :pagination="true"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1200, y: 'calc(82vh-150px )' }"
    >
      <!-- 'calc(82vh - 150px )' -->
      <!-- 
        - 自定义该列内容的显示，span内部可以写要显示的内容
        - slot-scope的内容
          - text 该单元格的数据
          - record 该单元格所在行的整体数据
          - index 该单元格所在行的索引
      -->
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_name" slot-scope="text, record">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <!-- info框 -->
            <span>{{ text }}</span>
          </template>
          <!-- <span>{{ text.split("直辖市-", 2)[1] }}</span> -->
          <span
            @click="
              isShowBtns.indexOf('SiteList-/api/worksite/detail') > -1
                ? JumpSubpage(record, 'SiteParticulars', '详情')
                : ''
            "
            style="cursor: pointer"
            >{{ text.replace(/直辖市|-北京|-天津/g, "") }}</span
          >
        </a-tooltip>
      </span>
      <span slot="ws_bind_status" slot-scope="text">
        <span class="status1" v-show="text === '1'">{{
          text | FBindStatus
        }}</span>
        <span v-show="text === '2'">{{ text | FBindStatus }}</span>
      </span>
      <span slot="无人施工总天数">
        无人施工总天数
        <a-tooltip>
          <template slot="title"
            >绑定期间有人打卡或现场出现穿工服者视为有人施工、此外即为无人施工</template
          >
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="nowork" slot-scope="text">
        {{ text ? text + "天" : "-" }}
      </span>
      <span slot="ws_status" slot-scope="text">
        {{ text | FSiteStatus }}
      </span>
      <span slot="ws_service_type" slot-scope="text">
        {{ text }}
      </span>
      <span slot="ws_attendance" slot-scope="text">
        {{ text | SiteAttendance }}
      </span>
      <span slot="d_status" slot-scope="text, record">
        {{ record.ws_bind_status == "1" ? "-" : text == "1" ? "在线" : "离线" }}
      </span>
      <!--  -->
      <span slot="已绑定总天数">
        已绑定总天数
        <a-tooltip>
          <template slot="title">累计绑定时长</template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <!-- ws_dispatch -->
      <span slot="ws_dispatch" slot-scope="text">
        {{ text == 1 ? "已派工" : "未派工" }}
      </span>
      <!-- 照片 -->
      <span slot="ws_img" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record.ws_name)">
          <!-- <span class="info" @click="showInfo(text, record)"> -->
          <div class="actions">
            <div v-if="text != ''" class="icon_out">
              <img
                :src="text"
                width="100%"
                height="100%"
                class="imgname"
                href="javascript:;"
              />
              <a-icon type="zoom-in" class="icon" />
            </div>
            <div v-else>暂无快照</div>
          </div>
        </span>
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <!-- <span v-if="record.ws_bind_status === '2' && record.ws_status === '1'"> -->
        <span class="flex_nowarp">
          <a
            @click="JumpSubpage(record, 'SiteParticulars', '详情')"
            v-if="isShowBtns.indexOf('SiteList-/api/worksite/detail') > -1"
            >详情</a
          >
          <a
            @click="JumpSubpage(record, 'SitePreview', '直播')"
            v-if="
              record.ws_bind_status === '2' &&
              isShowBtns.indexOf('SiteList-/api/worksite/camera/video') > -1
            "
            >直播</a
          >
          <!--  record.d_status === '1' && -->
          <a
            @click="JumpSubpage(record, 'SiteSnapshot', '快照')"
            v-if="isShowBtns.indexOf('SiteList-/api/worksite/snapshot') > -1"
            >快照</a
          >
          <a
            @click="JumpSubpage(record, 'SitePlayback', '回放')"
            v-if="
              isShowBtns.indexOf('SiteList-/api/worksite/playbackstart') > -1
            "
            >回放</a
          >
          <a
            @click="JumpSubpage(record, 'SiteAttend', '考勤')"
            v-if="isShowBtns.indexOf('SiteList-/api/worksite/attendance') > -1"
            >考勤</a
          >
          <a
            @click="JumpSubpage(record, 'SiteEdit', '修改', 'edit')"
            v-if="
              isShowBtns.indexOf('SiteList-/api/worksite/updateworksite') > -1
            "
            >修改</a
          >
          <br />
          <a
            @click="JumpSubpage(record, 'DeviceBind', '设备绑定', 'Bind')"
            v-if="isShowBtns.indexOf('SiteList-/api/qzw/devicebind') > -1"
            >设备绑定</a
          >
          <a
            @click="JumpSubpage(record, 'DeviceBind', '设备解绑', 'UnBind')"
            v-if="isShowBtns.indexOf('SiteList-/api/qzw/deviceunbound') > -1"
            >解绑</a
          >
          <a-popconfirm
            title="确定删除此条工地信息吗?"
            @confirm="() => onDelete(record)"
            v-if="isShowBtns.indexOf('SiteList-/api/worksite/delws') > -1"
          >
            <a class="delName">删除</a>
          </a-popconfirm>
        </span>
      </span>
    </s-table>

    <!-- 展开弹出框 -->
    <!-- :width="800" -->
    <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <SitePreview
        :record="record"
        v-if="SitePreview"
        :SiteList_previer_true="SiteList_previer_true"
        @JumpSubpage="JumpSubpage"
        @onClose="onClosePreview"
        ref="SitePreview"
      ></SitePreview>
      <SiteSnapshot :record="record" v-if="SiteSnapshot"></SiteSnapshot>
      <SitePlayback
        :record="record"
        v-if="SitePlayback"
        ref="SitePlayback"
      ></SitePlayback>
      <SiteAttend :record="record" v-if="SiteAttend"></SiteAttend>

      <SiteEdit
        :formType="formType"
        :record="record"
        v-if="SiteEdit"
        @emitEdit="emitEdit"
      ></SiteEdit>
      <SiteParticulars
        :record="record"
        v-if="SiteParticulars"
      ></SiteParticulars>
      <DeviceBind
        v-if="DeviceBind"
        :record="record"
        :formType="formType"
        @onClose="onClose"
      />
    </a-drawer>

    <!-- 导出弹出框 -->
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="exportVisible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
    <!-- 快照放大弹出框 -->
    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">快照</div>
      <div v-if="Image.length > 77" style="width: 100%" id="Image">
        <img :src="Image" width="100%" href="javascript:;" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image)">下载图片</div>
        </div>
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
  </div>
</template>

<script>
import { STable, Ellipsis, SSearch, ColumnsSet } from "@/components";
import { GetServiceList, DelSiteinfo } from "@/api/site";
import { ExportSiteList } from "@/api/export";
import SitePreview from "./SitePreview.vue";
import SiteSnapshot from "./SiteSnapshot.vue";
import SitePlayback from "./SitePlayback.vue";
import SiteAttend from "./SiteAttend.vue";
import SiteParticulars from "./SiteParticulars.vue"; //详情
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetCompanies, GetCompaniesStore } from "@/api/device";
import SiteEdit from "./SiteEdit.vue";
import { urlConfig } from "@/config/config";
import moment from "moment";
import DeviceBind from "./DeviceBind.vue";
// 搜索条件
const queryItems = [
  // { label: "分公司名称", value: "bc_name", type: "input" },

  { label: "项目经理", value: "w_name", type: "input" },
  {
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  {
    label: "设备所属分公司",
    value: "BcCs_id",
    type: "TreeSelect",
    range: ["bc_id", "cs_id"],
    selectOption: "CompanystoreList",
    defaultValue: ["", ""],
  },
  { label: "工地名称", value: "ws_name", type: "input", defaultValue: "" },
  { label: "工程管家", value: "w_gj_name", type: "input" },
  {
    label: "绑定状态",
    value: "ws_bind_status",
    type: "select",
    selectOption: "SiteBindStatus",
    defaultValue: "2",
  },
  {
    label: "工地状态",
    value: "ws_status",
    type: "select",
    selectOption: "SiteStatus",
    defaultValue: "1",
  },
  {
    label: "设备状态",
    value: "d_status",
    type: "select",
    selectOption: "SiteDeviceStatus",
    defaultValue: "1",
  },
  // {
  //   label: "业务类型",
  //   value: "ws_service_type",
  //   type: "select",
  //   selectOption: "SiteServiceType",
  // },
  {
    label: "派工状态",
    value: "ws_dispatch",
    type: "select",
    selectOption: "SitePaiGongStatus",
  },
  {
    label: "无人施工天数",
    value: "nowork",
    type: "select",
    selectOption: "NoWorksSiteList",
    defaultValue: "0",
  },
  {
    label: "今日打卡状态",
    value: "ws_attendance",
    type: "select",
    selectOption: "SiteAttendanceType",
  },
  { label: "合同编号", value: "ws_only", type: "input" },
  { label: "设备imei号", value: "d_imei", type: "input" },
  {
    label: "设备绑定日期",
    // colon: "true",
    // help: "默认查询截止今天7天内数据",
    value: "ws_bind_time",
    type: "dateRange",
    range: ["ws_bind_time_start", "ws_bind_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
];
// 表格内容
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    fixed: "left",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "工地所属分公司", // 表头文字
    width: "120px",
    /**
     * 表头取的字段
     * 若从返回数据中取字段，则写返回数据的参数名
     * 若自定义字段（例如序号、操作），可以自定义名称；
     *    自定义的名称需要与template中自定义span的slot对应
     */
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "工地名称",
    width: "400px",
    dataIndex: "ws_name",
    /**
     * 如果要自定义该列表格内容的显示，需要写scopedSlots
     * 具体自定义内容见template中<s-table>的写法
     */
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "最新快照",
    width: "120px",
    scopedSlots: { customRender: "ws_img" },
    // fixed: "right",
    dataIndex: "file_path",
  },
  {
    title: "设备状态",
    width: "90px",
    dataIndex: "d_status",
    scopedSlots: { customRender: "d_status" },
    align: "center",
  },
  {
    title: "绑定状态",
    width: "90px",
    dataIndex: "ws_bind_status",
    scopedSlots: { customRender: "ws_bind_status" },
    align: "center",
  },
  {
    title: "工地状态",
    width: "90px",
    dataIndex: "ws_status",
    scopedSlots: { customRender: "ws_status" },
    align: "center",
  },
  {
    title: "工程管家",
    width: "90px",
    dataIndex: "w_name_gj",
    align: "center",
  },
  {
    title: "项目经理",
    width: "90px",
    dataIndex: "w_name_jl",
    align: "center",
  },
  {
    title: "今日打卡状态",
    width: "120px",
    dataIndex: "ws_attendance",
    scopedSlots: { customRender: "ws_attendance" },
    align: "center",
  },
  {
    title: "合同编号",
    width: "160px",
    dataIndex: "ws_only",
    align: "center",
  },
  {
    // title: "已绑定总天数",
    width: "130px",
    dataIndex: "ws_bind_time_sum",
    // scopedSlots: { customRender: "ws_bind_time_sum" },
    slots: { title: "已绑定总天数" },
    align: "center",
  },
  {
    // title: "无人施工总天数",
    width: "135px",
    dataIndex: "nowork",
    scopedSlots: { customRender: "nowork" },
    slots: { title: "无人施工总天数" },
    align: "center",
  },
  {
    title: "派工状态",
    width: "90px",
    dataIndex: "ws_dispatch",
    scopedSlots: { customRender: "ws_dispatch" },
    align: "center",
  },
  {
    title: "业务类型",
    width: "90px",
    dataIndex: "ws_service_type",
    align: "center",
  },
  {
    title: "操作",
    width: "260px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];
const orderParam = [
  "ws_name",
  "w_name",
  "w_gj_name",
  "bc_name",
  "nowork",
  "ws_bind_time_start",
  "ws_bind_time_end",
  "ws_bind_status",
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "ws_only",
  "d_status",
  "ws_dispatch",
  "bc_id",
  "cs_id",
  "d_imei",
  "pageNo",
  "pageSize",
];
export default {
  name: "SiteList",
  components: {
    STable,
    Ellipsis,
    SSearch,
    SitePreview,
    SiteSnapshot,
    SitePlayback,
    SiteAttend,
    SiteParticulars,
    SiteEdit,
    ColumnsSet,
    DeviceBind,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      expand: false,
      tableColumns: [],
      // 查询参数
      queryParam: {
        ws_name: "",
        w_name: "",
        w_gj_name: "",
        bc_name: "",
        nowork: "",
        ws_bind_time_start: "",
        ws_bind_time_end: "",
        ws_bind_status: "2",
        ws_status: "1",
        d_status: "1",
        ws_service_type: "",
        ws_attendance: "",
      },
      // 弹出框
      title: "",
      visible: false,
      record: {},
      SitePreview: false,
      SiteSnapshot: false,
      SitePlayback: false,
      SiteAttend: false,
      SiteEdit: false,
      SiteParticulars: false,
      DeviceBind: false,
      formType: "",
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // 导出
      confirmLoading: false,
      exportVisible: false,
      SiteList_previer_true: true,
      visibleImg: false,
      Image: "",
      ImgName: "",
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      dateFormat: "YYYY/MM/DD",
      isShowBtns: [],
    };
  },
  created() {
    if (this.$route.query.ws_bind_time_start) {
      this.queryItems[13].defaultValue = [
        moment(this.$Format(this.$route.query.ws_bind_time_start)),
        moment(this.$Format(this.$route.query.ws_bind_time_end)),
      ];
      this.queryParam.ws_bind_time_start = this.$route.query.ws_bind_time_start;
      this.queryParam.ws_bind_time_end = this.$route.query.ws_bind_time_end;
    } else if (this.$route.query && !this.$route.query.ws_bind_time_start) {
      // // 为七天前的日期和今天的日期
      // var sevenTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
      // // let date = this.$Format(Math.round(sevenTime / 1000));
      // // console.log(date);
      // this.queryItems[13].defaultValue = [
      //   moment(sevenTime),
      //   moment(moment(new Date()).format("YYYY-MM-DD")),
      // ];
      // this.queryParam.a_time_start = Math.round(sevenTime / 1000);
      // this.queryParam.a_time_end = Math.round(new Date().getTime() / 1000);
    }
    if (this.$route.query && Object.keys(this.$route.query) != "") {
      console.log("this.$route.query", this.$route.query);
      setTimeout(() => {
        this.$refs.SSearch.expandChange();
      }, 10);
      // console.log(this.$route.query);
      for (let item in this.$route.query) {
        // 默认值的改变
        this.queryItems.forEach((el) => {
          if (el.value == item) {
            el.defaultValue = this.$route.query[item];
          }
        });
        // 查询值的改变
        for (let index in this.queryParam) {
          if (index == item) {
            this.queryParam[index] = this.$route.query[item];
          }
          // console.log(this.queryParam[index]);
        }
      }
      // 处理 跳转  分公司名称
    } else {
      this.queryItems[13].defaultValue = [];
    }

    // this.JumpSubpage(undefined, 'SiteEdit', '新建');
    this.tableColumns = columns;
    let query = this.$route.query;
    // this.queryParam.ws_name = this.$route.query.ws_name;
    // this.queryItems[2].defaultValue = query.ws_name;
    // 地图页面跳转传值
    if (this.$route.query.ws_name) {
      this.queryParam = [];
      // 默认值的改变

      this.queryItems.forEach((el) => {
        if (el.defaultValue) {
          el.defaultValue = "0";
          this.queryParam.ws_name = this.$route.query.ws_name;
          this.queryItems[3].defaultValue = query.ws_name;
        }
      });
      this.queryItems[13].defaultValue = [];
    }
    this.filters = SelectOptions;
    if (this.queryItems.some((el) => el.selectOption == "CompanyList")) {
      GetCompanies().then((res) => {
        // console.log(res);
        this.filters.CompanyList.length = 0;
        this.filters.CompanyList.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyList.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "CompanystoreList")) {
      let order = ["bc_id", "bc_name", "cs_data"];
      const param = {
        bc_id: "",
        bc_name: "",
        cs_data: "",
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };
      GetCompaniesStore(params).then((res) => {
        // console.log(res.data);
        // this.filters.CompanystoreList.length = 0;
        this.filters.CompanystoreList = [];
        this.filters.CompanystoreList.push({
          value: "",
          label: "全部",
          cs_data: [],
        });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanystoreList.push({
            value: element.bc_id,
            label: element.bc_name,
            cs_data: element.cs_data,
          });
        }
      });
    }
    // if (this.$route.query.ws_bind_status != undefined) {
    //   // console.log(this.queryParam);
    //   // this.queryParam = this.$route.query;
    //   // console.log(this.queryParam);
    //   console.log(this.$route.query);
    //   console.log(this.$route.query.ws_bind_status);
    //   this.queryItems[4].defaultValue = this.$route.query.ws_bind_status;
    //   console.log(this.queryItems[4].defaultValue);
    // }
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("SiteListColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      this.tableColumns = columns.filter((el) => value.includes(el.dataIndex));
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      if (this.queryParam.ws_name != undefined) {
        this.$set(
          this.queryParam,
          "ws_name",
          this.queryParam.ws_name.replace(/[, ]/g, "")
        );
      }
      // 汇总所有入参：表格分页，查询条件
      const param = Object.assign({}, parameter, this.queryParam);
      /**
       * 入参排序，按照接口文档给出的顺序排
       * $order为全局方法，在utils/global.js中定义
       * 排序规则在本文件中定义orderParam
       * */
      const requestParameters = this.$order(param, orderParam);
      /**
       * 排序后生成新的参数，增加排序后md5加密参数
       * $verify为全局方法，在utils/global.js中定义
       * 排序规则在本文件中定义orderParam
       * */
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetServiceList(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        let arr = res.data.data;
        const url = this.pathUrl;
        arr.forEach((value) => {
          // console.log(value);
          this.wsModel = value.ws_model;
          let img = value.file_path;
          value.file_path = url + img;
        });
        return res.data;
      });
    },
    fetchList(value) {
      this.queryParam = value;
      // STable提供的刷新组件的方法，调用即可请求接口刷新表格
      this.$refs.table.refresh(true);
    },
    JumpSubpage(record, showChild, title, formType) {
      // 根据点击按钮不同，显示不同内容
      this.visible = true;
      this.title = "工地列表 - 工地" + title;
      this.record = record;
      this[showChild] = true;
      this.formType = formType;
      // console.log(this.record);
    },
    emitEdit() {
      this.visible = false;
      this.$refs.table.refresh(true);
    },
    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
      this.SiteSnapshot = false;
      this.SiteAttend = false;
      this.SiteEdit = false;
      this.SiteParticulars = false;
      this.DeviceBind = false;
      if (this.SitePreview) {
        this.$refs.SitePreview.close();
        this.SitePreview = false;
      }
      if (this.SitePlayback) {
        this.$refs.SitePlayback.close();
        this.SitePlayback = false;
      }
    },
    // 关闭直播   供直播组件调用关闭直播
    onClosePreview() {
      this.SitePreview = false;
    },
    // 导出
    handleExport() {
      this.confirmLoading = false;
      this.exportVisible = true;
    },
    exportOk() {
      this.confirmLoading = true;
      const params = {
        ws_name: this.queryParam.ws_name,
        w_name: this.queryParam.w_name,
        w_gj_name: this.queryParam.w_gj_name,
        bc_name: this.queryParam.bc_name,
        nowork: this.queryParam.nowork,
        ws_bind_time_start: this.queryParam.ws_bind_time_start,
        ws_bind_time_end: this.queryParam.ws_bind_time_end,
        ws_bind_status: this.queryParam.ws_bind_status,
        ws_status: this.queryParam.ws_status,
        ws_service_type: this.queryParam.ws_service_type,
        ws_attendance: this.queryParam.ws_attendance,
        ws_only: this.queryParam.ws_only,
        d_status: this.queryParam.d_status,
        ws_dispatch: this.queryParam.ws_dispatch,
        bc_id: this.queryParam.bc_id,
        cs_id: this.queryParam.cs_id,
        d_imei: this.queryParam.d_imei,
      };
      ExportSiteList(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "工地列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.exportVisible = false;
        this.confirmLoading = false;
      });
    },
    exportCancel() {
      this.exportVisible = false;
    },
    showInfo(url, ws_name) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = ws_name;
      this.Image = url;
    },
    imgCancel() {
      this.visibleImg = false;
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
    // 删除工地信息

    onDelete(record) {
      console.log("record", record);
      const param = {
        ws_id: record.ws_id,
      };
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return DelSiteinfo(params).then((res) => {
        console.log(res);
        this.$refs.table.refresh(true);
      });
    },
  },
};
</script>
<!-- 
 - 当前页面的样式
 - lang="less" 表示使用less语法（主要使用less变量、css嵌套）
 - scoped 表示定义的样式只在当前组件中生效，不影响其他组件
 - 若要写全局生效的css样式，建议写在全局样式文件中global.less（global.less已经在main.js中全局注册）
 -->
<style lang="less" scoped>
.action a {
  margin-right: 8px;
}
.status1 {
  color: #96959d;
}
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
// /deep/.ant-table table {
//   text-align: center;
// }
// 鼠标悬停蒙层
.icon_out {
  // width: 209px;
  // width: 221px;
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
}
.icon {
  font-size: 25px;
  left: 43%;
  top: 40%;
  position: absolute;
  margin: auto;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    width: 100%;
    height: 60px;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info_out {
  position: relative;
  cursor: pointer;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}

#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
.delName {
  color: red;
}
</style>
